import Vue from 'vue'
/**
 * @param {number} value Number value.
 * @return {number} Count of number of decimals in number
 */
const countDecimals = function (value) {
  const text = value.toString()
  // verify if number 0.000005 is represented as "5e-6"
  if (text.includes('e-')) {
    const [base, trail] = text.split('e-')
    const deg = parseInt(trail, 10)
    return deg
  }
  // count decimals for number in representation like "0.123456"
  if (Math.floor(value) !== value)
    return value.toString().split('.')[1].length || 0

  return 0
}

Vue.filter('toCurrency', (value) => {
  const parsedNumber = parseFloat(value)
  if (typeof value !== 'number') {
    if (isNaN(parsedNumber))
      return value
  }

  const decimals = countDecimals(value)
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 3,
    // maximumFractionDigits: 3,
  })
  return parsedNumber ? formatter.format(parsedNumber) : formatter.format(value)
})

Vue.filter('toFormattedNumber', (value) => {
  if (typeof value !== 'number')
    return value

  // var formatter = new Intl.NumberFormat();
  // return formatter.format(value);
  return value.toLocaleString()
})
