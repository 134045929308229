import Vue from 'vue'
import APISTATE from '@/js/models/apistate'
import ShipTo from '@/js/models/ship-to'

import {
  ACTIVE_SHIPTO,
  ACTIVE_SHIPTO_FAILURE,
  INIT_SHIPTO_LIST,
  REQUEST_SHIPTO_LIST,
  SET_ACTIVE_SHIPTO,
  SHIPTO_DISTRIBUTOR,
  SHIPTO_LIST,
  SHIPTO_LIST_FAILURE,
} from '@/js/store/types'

// State object
const initialState = {
  shipToApiState: APISTATE.INIT,
  shipToErrorMessage: '',
  isShipToDistributor: false,
  availableShipTos: [],
  activeShipTo: null,
}

const state = initialState

// Getter functions
const getters = {
  shipToApiState: (state) => {
    return state.shipToApiState
  },
  isShipToDistributor: (state) => {
    return state.isShipToDistributor
  },
  availableShipTos: (state) => {
    return state.availableShipTos
  },
  activeShipToName: (state) => {
    const shipTo = state.availableShipTos.find(obj => obj.ShipToId === state.activeShipTo)
    return `${shipTo.ShipToId}: ${shipTo.AddressLine1}, ${shipTo.City}, ${shipTo.State}`
  },
  activeShipTo: (state) => {
    return state.activeShipTo
  },
}

// Actions
const actions = {
  [INIT_SHIPTO_LIST]({ commit, getters }, purl) {
    commit(REQUEST_SHIPTO_LIST)
    const params = {
      erpShipToId: getters.activeShipTo,
      useCache: false,
    }

    const query = Object.keys(params)
      .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
      .join('&')

    const url = `${purl}/uc/checkout/shipto?${query}`
    Vue.http.get(url).then((response) => {
      if (response.data
                    && response.data.Status
                    && response.data.Status === 'success'
                    && response.data.Data
                    && response.data.Data.data) {
        const data = response.data.Data.data
        commit(SHIPTO_DISTRIBUTOR, data.IsDistributor)
        commit(SHIPTO_LIST, data.AvailableShipTos)
        commit(ACTIVE_SHIPTO, data.SelectedShipToId)
      }
      else {
        if (response.data.Status && response.data.Status === 'failed') {
          this.errorMessage = response.data.Message
          if (response.data.Data && response.data.Data.stacktrace)
            this.errorMessage = response.data.Data.stacktrace

          this.$toastify.toastError(this.errorMessage)
        }
        commit(SHIPTO_LIST_FAILURE, 'response contained no shipping data')
        commit(ACTIVE_SHIPTO, null)
      }
    }, (err) => {
      commit(SHIPTO_LIST_FAILURE, err)
      commit(ACTIVE_SHIPTO, null)
    })
  },
  [SET_ACTIVE_SHIPTO]({ commit }, shipToId) {
    commit(ACTIVE_SHIPTO, shipToId)
  },
}
// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState()
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key]
    })
  },
  [SHIPTO_LIST_FAILURE](state, error) {
    state.shiptoErrorMessage = error
    state.shipToApiState = APISTATE.ERROR
  },
  [REQUEST_SHIPTO_LIST](state) {
    state.shipToApiState = APISTATE.LOADING
  },
  [SHIPTO_DISTRIBUTOR](state, isDistributor) {
    state.isShipToDistributor = isDistributor
  },
  [SHIPTO_LIST](state, shiptos) {
    state.availableShipTos = shiptos
    state.shipToApiState = APISTATE.LOADED
  },
  [ACTIVE_SHIPTO](state, shipToId) {
    const index = state.availableShipTos.findIndex(value => value.ShipToId === shipToId)

    if (!(index === undefined || index === null) && index > -1) {
      state.activeShipTo = shipToId
    }
    else {
      if (state.availableShipTos && state.availableShipTos.length) {
        const firstShipTo = state.availableShipTos[0]
        if (firstShipTo) {
          console.log('active_shipto first shipto', firstShipTo.ShipToId)
          state.activeShipTo = firstShipTo.ShipToId
        }
        else {
          console.log('active_shipto else null')

          state.activeShipTo = null
        }
      }
      else {
        console.log('active_shipto null')

        state.activeShipTo = null
      }
    }
  },
}

const hydrate = function (state) {
  if (state.availableShipTos) {
    const availableShipTos = []
    state.availableShipTos.forEach((value) => {
      const shipto = new ShipTo()
      shipto.fromJson(value)
      availableShipTos.push(shipto)
    })
    state.availableShipTos = availableShipTos
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
  hydrate,
}
