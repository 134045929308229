import Vue from "vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

Vue.use(Loading);

class Loader {
  constructor() {
    this.loader = null;
  }
  
  loaderStart(ref) {
    var props = {
      // props
      color: '#0077d4',
      container: null,
      fullPage: true,
      canCancel: false,
      loader: 'spinner',
      width: 64,
      height: 64,
      backgroundColor: '#ffffff',
      opacity: 0.5,
      zIndex: 999
    };
    if (ref) {
      props.container = ref;
    }
    this.loader = Vue.$loading.show(props);
  }
  
  loaderEnd() {
    this.loader.hide();
  }
  
}

export default new Loader()
