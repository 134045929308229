import Vue from 'vue'
import APISTATE from '@/js/models/apistate'
import ShippingMethod from '@/js/models/shipping-method'

import {
  ACTIVE_SHIPPING,
  FREIGHT_TERMS_OPTIONS,
  INIT_SHIPPING_LIST,
  REQUEST_SHIPPING_LIST,
  SET_ACTIVE_SHIPPING,
  SET_SHIPPING_INFO,
  SHIPPING_CHECKOUT_MODEL,
  SHIPPING_INFO,
  SHIPPING_LIST,
  SHIPPING_LIST_FAILURE,
  SHIPPING_VIA_OPTIONS,
  VEHICLE_TYPE_OPTIONS,
} from '@/js/store/types'

// State object
const initialState = {
  shippingMethodApiState: APISTATE.INIT,
  shippingMethodErrorMessage: '',
  checkoutModel: null,
  availableShippingMethods: [],
  activeShippingMethod: null,
}

const state = initialState

// Getter functions
const getters = {
  shippingMethodApiState: (state) => {
    return state.shippingMethodApiState
  },
  shippingMethodErrorMessage: (state) => {
    return state.shippingMethodErrorMessage
  },
  checkoutModel: (state) => {
    return state.checkoutModel
  },
  availableShippingMethods: (state) => {
    return state.availableShippingMethods
  },
  activeShippingMethod: (state) => {
    return state.activeShippingMethod
  },
  freightTermOptions: (state) => {
    return FREIGHT_TERMS_OPTIONS
  },
  vehicleTypeOptions: (state) => {
    return VEHICLE_TYPE_OPTIONS
  },
  shipViaOptions: (state) => {
    return SHIPPING_VIA_OPTIONS
  },
}

// Actions
const actions = {
  [INIT_SHIPPING_LIST]({ commit, getters }, purl) {
    commit(REQUEST_SHIPPING_LIST)
    const params = {
      shippingMethodId: getters.activeShippingMethod,
    }

    const query = Object.keys(params)
      .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
      .join('&')

    const url = `${purl}/uc/checkout/shipping?${query}`
    Vue.http.get(url).then((response) => {
      if (response.data
                    && response.data.Status
                    && response.data.Status == 'success'
                    && response.data.Data
                    && response.data.Data.data) {
        const data = response.data.Data.data

        for (let i = 0; i < data.AvailableShippingMethods.length; i++)
          data.AvailableShippingMethods[i].Name = `${data.AvailableShippingMethods[i].Name} (${data.AvailableShippingMethods[i].DisplayPrice})`

        commit(SHIPPING_CHECKOUT_MODEL, {
          NextStepUrl: data.NextStepUrl,
          PreviousStepUrl: data.PreviousStepUrl,
          AvailableCountries: data.AvailableCountries,
          AvailableFreightTermOptions: data.AvailableFreightTermOptions,
          AvailableVehicleTypes: data.AvailableVehicleTypes,
          TotalWeight: data.TotalWeight,
          WeightUnit: data.WeightUnit,
          ShippingInfo: data.FreightDetails,
          IsDistributor: data.IsDistributor,
        })
        commit(SHIPPING_LIST, data.AvailableShippingMethods)
        if (!state.activeShippingMethod && data.AvailableShippingMethods && data.AvailableShippingMethods.length)
          commit(ACTIVE_SHIPPING, data.AvailableShippingMethods[0].ShippingMethodId)
      }
      else {
        if (response.data.Status && response.data.Status == 'failed') {
          this.errorMessage = response.data.Message
          if (response.data.Data && response.data.Data.stacktrace)
            this.errorMessage = response.data.Data.stacktrace

          this.$toastify.toastError(this.errorMessage)
        }
        commit(SHIPPING_LIST_FAILURE, 'response contained no shipping method data')
        commit(ACTIVE_SHIPPING, null)
      }
    }, (err) => {
      commit(SHIPPING_LIST_FAILURE, err)
      commit(ACTIVE_SHIPPING, null)
    })
  },
  [SET_ACTIVE_SHIPPING]({ commit }, shippingMethodId) {
    commit(ACTIVE_SHIPPING, shippingMethodId)
  },
  [SET_SHIPPING_INFO]({ commit }, model) {
    commit(SHIPPING_INFO, model)
  },
}
// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState()
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key]
    })
  },
  [SHIPPING_LIST_FAILURE](state, error) {
    state.shippingMethodErrorMessage = error
    state.shippingMethodApiState = APISTATE.ERROR
  },
  [REQUEST_SHIPPING_LIST](state) {
    state.shippingMethodApiState = APISTATE.LOADING
  },
  [SHIPPING_CHECKOUT_MODEL](state, checkoutModel) {
    state.checkoutModel = checkoutModel
  },
  [SHIPPING_LIST](state, shippingMethods) {
    state.availableShippingMethods = shippingMethods
    state.shippingMethodApiState = APISTATE.LOADED
  },
  [ACTIVE_SHIPPING](state, shippingMethodId) {
    const index = state.availableShippingMethods.findIndex(value => value.ShippingMethodId === shippingMethodId)

    if (!(index === undefined || index === null) && index > -1) {
      state.activeShippingMethod = shippingMethodId
    }
    else {
      if (state.availableShippingMethods && state.availableShippingMethods.length) {
        const firstShippingMethod = state.availableShippingMethods[0]
        if (firstShippingMethod)
          state.activeShippingMethod = firstShippingMethod.ShippingMethodId

        else
          state.activeShippingMethod = null
      }
      else {
        state.activeShippingMethod = null
      }
    }
  },
  [SHIPPING_INFO](state, model) {
    state.checkoutModel.ShippingInfo = model
  },
}

const hydrate = function (state) {
  if (state.availableShippingMethods) {
    const availableShippingMethods = []
    state.availableShippingMethods.forEach((value) => {
      const shippingMethod = new ShippingMethod()
      shippingMethod.fromJson(value)
      availableShippingMethods.push(shippingMethod)
    })
    state.availableShippingMethods = availableShippingMethods
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
  hydrate,
}
