import {
    SET_PURL,
    ACTIVE_PURL
} from '@/js/store/types'

// State object
const initialState = {
    //routerHistory: [],
    purl : ''
};

const state = initialState;

// Getter functions
const getters = {
    purl: state => {
        return state.purl;
    },
    // routerHistory: state => {
    //     return state.routerHistory;
    // },
    // previousRoute: (state) => {
    //     const historyLen = state.routerHistory.length;
    //     if (historyLen == 0) return null;
    //     return state.routerHistory[historyLen - 1];
    // },    
};


// Actions 
const actions = {    
    // ['set_route']({ commit }, route) {
    //     console.log('action set_route', route);
    //     let routeData = {
    //         name: route.name,
    //         params: route.params,
    //         query: route.query,
    //         path: route.path,
    //         fullPath: route.fullpath
    //     };
    //     commit('route', routeData);
    // },
    [SET_PURL]({ commit }, purl) {
        commit(ACTIVE_PURL, purl);
    },
};
// Mutations
const mutations = {
    RESET(state) {
        const newState = initialState();
        Object.keys(newState).forEach(key => {
            state[key] = newState[key]
        });
    },
    // ['route'](state, route) {
    //     if(route) {
    //         if(state.routerHistory.length == 2) {
    //             state.routerHistory.shift();
    //         }
    //         state.routerHistory.push(route);
    //     }               
    // },
    [ACTIVE_PURL](state, purl) {
        if(purl) {           
            state.purl = purl;
        }               
    }
};

const hydrate = function (state) {
    // if (state.routerHistory) {
    //     const routerHistory = []
    //     state.routerHistory.forEach(value => {            
    //         routerHistory.push(value)
    //     })
    //     state.routerHistory = routerHistory;
    // }

    if(state.purl) {
        state.purl = state.purl;
    }
};

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations,
    hydrate: hydrate
};
