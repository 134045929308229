import Vue from 'vue'
import APISTATE from '@/js/models/apistate'
import Customer from '@/js/models/customer'

import {
  ACTIVE_CUSTOMER,
  ACTIVE_CUSTOMER_FAILURE,
  ACTIVE_CUSTOMER_NAME,
  CUSTOMER_DISTRIBUTOR,
  CUSTOMER_LIST,
  CUSTOMER_LIST_FAILURE,
  INIT_CUSTOMER_LIST,
  IS_INTERNAL_EMPLOYEE,
  REQUEST_CUSTOMER_LIST,
  SET_ACTIVE_CUSTOMER,
  SET_ACTIVE_CUSTOMER_NAME,
  SET_USER_INFO,
  USER_INFO,
} from '@/js/store/types'

// State object
const initialState = {
  customerApiState: APISTATE.INIT,
  customerErrorMessage: '',
  isCustomerDistributor: false,
  availableCustomers: [],
  activeCustomer: null,
  syncTime: new Date(),
}

const state = initialState

// Getter functions
const getters = {
  customerApiState: (state) => {
    return state.customerApiState
  },
  isCustomerDistributor: (state) => {
    return state.isCustomerDistributor
  },
  availableCustomers: (state) => {
    return state.availableCustomers
  },
  activeCustomer: (state) => {
    return state.activeCustomer
  },
  activeCustomerName: (state) => {
    return state.activeCustomerName
  },
  isInternalEmployee: (state) => {
    return state.isInternalEmployee
  },
  userInfo: (state) => {
    return state.userInfo
  },
  syncTime: (state) => {
    return state.syncTime
  },
}

// Actions
const actions = {
  [INIT_CUSTOMER_LIST]({ commit, getters }) {
    commit(REQUEST_CUSTOMER_LIST)
    let isExpired = false
    // do a noop if not exipred from customer sync script
    if (getters.syncTime) {
      const ONE_HOUR = 60 * 60 * 1000 /* ms */
      isExpired = ((new Date()) - getters.syncTime) > ONE_HOUR
    }

    if (!isExpired) {
      const url = '/authapi/cartlist/customersync?useCache=false'
      Vue.http.get(url).then((response) => {
        if (response.data
                    && response.data.Status
                    && response.data.Status === 'success'
                    && response.data.Data
                    && response.data.Data.data) {
          const data = response.data.Data.data
          commit(CUSTOMER_DISTRIBUTOR, data.IsDistributor)
          commit(IS_INTERNAL_EMPLOYEE, data.IsInternalEmployee)
          commit(CUSTOMER_LIST, data.AvailableCustomers)
          commit(ACTIVE_CUSTOMER, data.SelectedErpCustomerId)
          commit(ACTIVE_CUSTOMER_NAME, data.SelectedCustomerName)
          commit(USER_INFO, data.UserInfo)
          // if (!state.activeCustomer && data.AvailableCustomers && data.AvailableCustomers.length) {
          //    commit(ACTIVE_CUSTOMER, data.AvailableCustomers[0].ErpId);
          // }
        }
        else {
          if (response.data.Status && response.data.Status === 'failed') {
            this.errorMessage = response.data.Message
            if (response.data.Data && response.data.Data.stacktrace)
              this.errorMessage = response.data.Data.stacktrace

            this.$toastify.toastError(this.errorMessage)
          }
          commit(CUSTOMER_LIST_FAILURE, 'response contained no customer data')
          commit(ACTIVE_CUSTOMER, null)
        }
      }, (err) => {
        commit(CUSTOMER_LIST_FAILURE, err)
        commit(ACTIVE_CUSTOMER, null)
      })
    }
  },
  [SET_ACTIVE_CUSTOMER]({ commit }, erpId) {
    commit(ACTIVE_CUSTOMER, erpId)
  },
  [SET_ACTIVE_CUSTOMER_NAME]({ commit }, customerName) {
    commit(ACTIVE_CUSTOMER_NAME, customerName)
  },
  [SET_USER_INFO]({ commit }, userInfo) {
    commit(USER_INFO, userInfo)
  },
}

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState()
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key]
    })
  },
  [CUSTOMER_LIST_FAILURE](state, error) {
    state.customerErrorMessage = error
    state.customerApiState = APISTATE.ERROR
  },
  [REQUEST_CUSTOMER_LIST](state) {
    state.customerApiState = APISTATE.LOADING
  },
  [CUSTOMER_DISTRIBUTOR](state, isDistributor) {
    state.isCustomerDistributor = isDistributor
  },
  [IS_INTERNAL_EMPLOYEE](state, isInternalEmployee) {
    state.isInternalEmployee = isInternalEmployee
  },
  [CUSTOMER_LIST](state, customers) {
    state.availableCustomers = customers
    state.customerApiState = APISTATE.LOADED
  },
  [ACTIVE_CUSTOMER_NAME](state, customerName) {
    state.activeCustomerName = customerName
  },
  [USER_INFO](state, userInfo) {
    state.userInfo = userInfo
    state.syncTime = new Date()
  },
  [ACTIVE_CUSTOMER](state, erpId) {
    if (state.isInternalEmployee) {
      // for internal employees, erpId will not be in the list of available customers
      state.activeCustomer = erpId
    }
    else {
      const index = state.availableCustomers.findIndex(value => value.ErpId === erpId)

      if (!(index === undefined || index === null) && index > -1) {
        state.activeCustomer = erpId
      }
      else {
        if (state.availableCustomers && state.availableCustomers.length) {
          const firstCustomer = state.availableCustomers[0]
          if (firstCustomer)
            state.activeCustomer = firstCustomer.ErpId
          else
            state.activeCustomer = null
        }
        else {
          state.activeCustomer = null
        }
      }
    }
  },
}

const hydrate = function (state) {
  if (state.availableCustomers) {
    const availableCustomers = []
    state.availableCustomers.forEach((value) => {
      const customer = new Customer()
      customer.fromJson(value)
      availableCustomers.push(customer)
    })
    state.availableCustomers = availableCustomers
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
  hydrate,
}
