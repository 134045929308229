import Vue from 'vue'
import APISTATE from '@/js/models/apistate'
import SearchQuery from '@/js/models/search-query'

import {
  ACTIVE_SEARCH_FILTERS,
  ACTIVE_SEARCH_QUERY,
  GET_SEARCH_PRICES,
  INIT_SEARCH_RESULT_LIST,
  REQUEST_SEARCH_RESULT_LIST,
  SEARCH_RESULT_CURRENT_END_PAGE,
  SEARCH_RESULT_CURRENT_PAGE,
  SEARCH_RESULT_CURRENT_START_PAGE,
  SEARCH_RESULT_LIST,
  SEARCH_RESULT_LIST_FAILURE,
  SEARCH_RESULT_TOTAL_COUNT,
  SEARCH_RESULT_TOTAL_PAGES,
  SEARCH_RESULT_UPDATE,
  SET_ACTIVE_SEARCH_FILTERS,
  SET_SEARCH_QUERY,
  SET_SEARCH_RESULT,
  USER_INFO,
} from '@/js/store/types'

// State object
const initialState = {
  searchResultApiState: APISTATE.INIT,
  searchResultErrorMessage: '',
  searchResults: [],
  searchPrices: [],
  searchFacets: [],
  currentFacets: {},
  searchResultTotalCount: 0,
  searchResultTotalPages: 0,
  searchResultCurrentPage: 0,
  searchResultCurrentStartPage: 0,
  searchResultCurrentEndPage: 0,
  activeSearchQuery: null,
  activeSearchFilters: null,
  syncResultsTime: new Date(),
}

const state = initialState

// Getter functions
const getters = {
  searchResultApiState: (state) => {
    return state.searchResultApiState
  },
  searchResults: (state) => {
    return state.searchResults
  },
  searchResultTotalCount: (state) => {
    return state.searchResultTotalCount
  },
  searchResultTotalPages: (state) => {
    return state.searchResultTotalPages
  },
  searchResultCurrentPage: (state) => {
    return state.searchResultCurrentPage
  },
  searchResultCurrentStartPage: (state) => {
    return state.searchResultCurrentStartPage
  },
  searchResultCurrentEndPage: (state) => {
    return state.searchResultCurrentEndPage
  },
  searchPrices: (state) => {
    return state.searchPrices
  },
  searchFacets: (state) => {
    return state.searchFacets
  },
  currentFacets: (state) => {
    return state.currentFacets
  },
  activeSearchQuery: (state) => {
    return state.activeSearchQuery
  },
  activeSearchFilters: (state) => {
    return state.activeSearchFilters
  },
  syncResultsTime: (state) => {
    return state.syncResultsTime
  },
}

// Actions
const actions = {
  [INIT_SEARCH_RESULT_LIST]({ commit, getters }, { section, searchParams }) {
    commit(REQUEST_SEARCH_RESULT_LIST)
    let isExpired = false
    // do a noop if not exipred from customer sync script
    if (getters.syncResultsTime) {
      const ONE_HOUR = 60 * 60 * 1000 /* ms */
      isExpired = ((new Date()) - getters.syncResultsTime) > ONE_HOUR
    }

    if (!isExpired) {
      // let url = '/authapi/cartlist/customersync';
      const url = `${window.location.origin}/api/search/${section}?${new URLSearchParams(searchParams).toString()}`
      console.log('calling search url with url', url, searchParams)
      Vue.http.get(url).then((response) => {
        if (response.data
                    && response.status
                    && response.status === 200
                    && response.body) {
          console.log('init search store got data', response.body)
          const data = response.body
          commit(ACTIVE_SEARCH_QUERY, searchParams)

          commit(SEARCH_RESULT_TOTAL_COUNT, data.TotalResultCount)
          commit(SEARCH_RESULT_TOTAL_PAGES, Math.ceil(data.TotalResultCount / searchParams.ps))
          commit(SEARCH_RESULT_CURRENT_PAGE, searchParams.pg)
          commit(SEARCH_RESULT_CURRENT_START_PAGE, ((data.CurrentPage - 1) * searchParams.ps) + 1)
          commit(SEARCH_RESULT_CURRENT_END_PAGE, data.CurrentStart + data.SearchResults.length - 1)

          if (data.SearchResults)
            commit(SEARCH_RESULT_LIST, data.SearchResults)

          // commit(ACTIVE_CUSTOMER, data.SelectedErpCustomerId);
          // commit(USER_INFO, data.UserInfo);
          // if (!state.activeCustomer && data.searchResults && data.searchResults.length) {
          //    commit(ACTIVE_CUSTOMER, data.searchResults[0].ErpId);
          // }
        }
        else {
          if (response.data.Status && response.data.Status === 'failed') {
            this.errorMessage = response.data.Message
            if (response.data.Data && response.data.Data.stacktrace)
              this.errorMessage = response.data.Data.stacktrace

            this.$toastify.toastError(this.errorMessage)
          }
          commit(SEARCH_RESULT_LIST_FAILURE, 'response contained no search result data')
          commit(ACTIVE_SEARCH_QUERY, null)
        }
      }, (err) => {
        commit(SEARCH_RESULT_LIST_FAILURE, err)
        commit(ACTIVE_SEARCH_QUERY, null)
      })
    }
  },
  [GET_SEARCH_PRICES]({ commit, getters }, productCodes) {
    const config = {
      params: {
        productCodeJson: JSON.stringify(productCodes),
      },
    }

    const url = `${window.location.origin}:9195/authapi/productprices${new URLSearchParams(config).toString()}`
    Vue.http.get(url).then((response) => {
      console.log('prices url called', response)
      if (response.data
                    && response.status
                    && response.status === 200
                    && response.body) {
        console.log('init search store got data', response.body)
        const data = response.body

        this.prices = data.Prices
        commit(SET_PRICES, data.Prices)
        const userInfo = {
          isLoggedIn: data.IsLoggedIn,
          isDistributor: data.IsDistributor,
          isLoaded: true,
        }

        commit(SET_USER_INFO, userInfo)

        if (userInfo.isLoggedIn) {
          if (userInfo.isDistributor) {
            commit(SET_MSRP_HEADING, 'MSRP')
            commit(SET_USERPRICE_HEADING, 'Your Price')
          }
          else {
            // MSRP not visible
            // this.userPriceHeading = 'Online Price'
            commit(SET_USERPRICE_HEADING, 'Online Price')
          }
        }
      }
      else {
        if (response.data.Status && response.data.Status === 'failed') {
          this.errorMessage = response.data.Message
          if (response.data.Data && response.data.Data.stacktrace)
            this.errorMessage = response.data.Data.stacktrace

          this.$toastify.toastError(this.errorMessage)
        }
        commit(SEARCH_RESULT_LIST_FAILURE, 'response contained no prices result data')
        commit(ACTIVE_SEARCH_QUERY, null)
      }
    }, (err) => {
      commit(SEARCH_RESULT_LIST_FAILURE, err)
      commit(ACTIVE_SEARCH_QUERY, null)
    })
  },
  [SET_SEARCH_RESULT]({ commit }, searchProps) {
    commit(SEARCH_RESULT_UPDATE, searchProps)
  },
  [SET_ACTIVE_SEARCH_FILTERS]({ commit }, filters) {
    commit(ACTIVE_SEARCH_FILTERS, filters)
  },
  [SET_SEARCH_QUERY]({ commit }, searchQuery) {
    commit(ACTIVE_SEARCH_QUERY, searchQuery)
  },
}

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState()
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key]
    })
  },
  [SEARCH_RESULT_LIST_FAILURE](state, error) {
    state.searchResultErrorMessage = error
    state.searchResultApiState = APISTATE.ERROR
  },
  [REQUEST_SEARCH_RESULT_LIST](state) {
    state.searchResultApiState = APISTATE.LOADING
  },
  [SEARCH_RESULT_LIST](state, results) {
    state.searchResults = results
    state.searchResultApiState = APISTATE.LOADED
  },
  [SEARCH_RESULT_UPDATE](state, searchProps) {
    Vue.set(state.searchResults[searchProps.index], searchProps.propertyName, searchProps.value)
  },
  [SEARCH_RESULT_TOTAL_COUNT](state, totalResultCount) {
    state.searchResultTotalCount = totalResultCount
  },
  [SEARCH_RESULT_TOTAL_PAGES](state, totalPages) {
    state.searchResultTotalPages = totalPages
  },
  [SEARCH_RESULT_CURRENT_PAGE](state, currentPage) {
    state.searchResultCurrentPage = currentPage
  },
  [SEARCH_RESULT_CURRENT_START_PAGE](state, currentStartPage) {
    state.searchResultCurrentStartPage = currentStartPage
  },
  [SEARCH_RESULT_CURRENT_END_PAGE](state, currentEndPage) {
    state.searchResultCurrentEndPage = currentEndPage
  },
  [ACTIVE_SEARCH_QUERY](state, searchQuery) {
    state.activeSearchQuery = searchQuery
  },
  [USER_INFO](state, userInfo) {
    state.userInfo = userInfo
    state.syncTime = new Date()
  },
}

const hydrate = function (state) {
  if (state.activeSearchQuery) {
    console.log('got hydrated search query', state.activeSearchQuery)
    const searchQuery = new SearchQuery()
    searchQuery.fromJson(state.searchQuery)
    searchQuery.bst = searchQuery.bst.split('"').join('')
    state.activeSearchQuery = searchQuery
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
  hydrate,
}
