/**
 * Automatically imports all the modules and exports as a single module object
 */

/** webpack */
//  const files = require.context('.', false,  /\.store\.js$/);
//  const modules = {};

//  files.keys().forEach(key => {

//      // create the module name from fileName
//      // remove the store.js extension and capitalize
//      const moduleName = key
//                     .replace(/(\.\/|\.store\.js)/g, '')
//                     .replace(/^\w/, c => c.toUpperCase())

//      modules[moduleName] = files(key).default || files(key);
//  });

/** vite */
const files = import.meta.globEager('./*.js')

const modules = {}
for (const key in files) {
  // if (key === "./index.js") continue;
  const moduleName = key
    .replace(/(\.\/|\.store\.js)/g, '')
    .replace(/^\w/, c => c.toUpperCase())
  modules[moduleName] = files[key].default
}

export default modules
