// State object
const initialState = {
    updateIteration: 0,
    triggerSubmit: 0,
    allowNavigate: false,
    checkoutTransactionId: null,
    checkoutSubmitted: false,
    widgets: [],
    submittedWidgets: 0
}

const state = initialState;


// Getter functions
const getters = {
    checkoutSubmitted: state => {
        return state.checkoutSubmitted;
    }
}

// Actions 
const actions = {
    checkoutSubmitted({commit, state}, transactionId) {
        if(!state.checkoutSubmitted) {
            commit('checkoutSubmitted', transactionId);
        } else {
            commit('checkoutReset');
        }
    },
    checkoutReset({commit}) {
        commit('checkoutReset');
    },
    widgetSubmitted({ commit, state }) {
        if ((state.submittedWidgets + 1) == state.widgets.length) {
            commit('allowNavigation');
        }
        else {
            commit('widgetSubmitted');
        }
    }
}

// Mutations
const mutations = {
    RESET(state) {
        const newState = initialState();
        Object.keys(newState).forEach(key => {
            state[key] = newState[key]
        });
    },
    update(state) {
        state.updateIteration++
    },
    // migrate to action
    triggersubmit(state) {
        state.submittedWidgets = 0;
        state.triggerSubmit++
    },
    allowNavigation(state) {
        state.allowNavigate = true;
    },
    checkoutReset(state) {
        state.transactionId = null;
        state.checkoutSubmitted = false;
    },
    checkoutSubmitted(state, transactionId) {
        state.transactionId = transactionId;
        state.checkoutSubmitted = true;
    },
    vuecreated(state, name) {
        state.widgets.push(name);
    },
    widgetSubmitted(state) {
        state.submittedWidgets++;
    },
}


const hydrate = function (state) {
    if (state.updateIteration) {
        state.updateIteration = initialState.updateIteration;
    }
    if (state.triggerSubmit) {
        state.triggerSubmit = initialState.triggerSubmit;
    }
    if (state.allowNavigate) {
        state.allowNavigate = initialState.allowNavigate;
    }
    if (state.widgets) {
        state.widgets = initialState.widgets;
    }
    if (state.submittedWidgets) {
        state.submittedWidgets = initialState.submittedWidgets;
    }
    if (state.checkoutSubmitted) {
        state.checkoutSubmitted = initialState.checkoutSubmitted;
    }
    if (state.checkoutTransactionId) {
        //state.checkoutTransactionId = initialState.checkoutTransactionId;
    }
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations,
    hydrate: hydrate
}