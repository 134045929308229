/**
* get props from inside <script></script> tags name props
* else get props from elements html data attribute
* @example
* <script type="application/json" props="true">
* { "props" : @Html.Raw(Newtonsoft.Json.JsonConvert.SerializeObject(props, new Newtonsoft.Json.JsonSerializerSettings{ ContractResolver = new Newtonsoft.Json.Serialization.CamelCasePropertyNamesContractResolver())) }
* </script>
*
* @example
* <section id="h_CommentBox" data-post-id="10" data-post-comment=".NET 5, Vue" class="__vue-root"></section>
* @param {HTMLElement} rootElement - The root html element of the vue app.
*/
function parseProps(rootElement) {
    var scriptElement = rootElement.querySelector("script[props]");
    var props = scriptElement === null ? Object.assign({}, rootElement.dataset) : JSON.parse(scriptElement.innerHTML).props;
    //console.log(props);
    return props;
}


export default { parseProps };