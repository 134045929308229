class SearchQuery {
  constructor(searchQuery) {
    if (searchQuery) {
      /**
             * The type of search to perform: site, products, services, resources, media
             */
      this.Section = searchQuery.Section
      this.site = searchQuery.site
      this.sorted = searchQuery.sorted
      this.bst = searchQuery.bst
      /**
             * The Page Size
             */
      this.ps = searchQuery.ps
      /**
             * The Page number to get (starting at 1)
             */
      this.pg = searchQuery.pg
    }
  }

  fromJson(json) {
    if (json) {
      this.Section = json.Section || 'site'
      this.site = json.site || 'Dayton'
      this.sorted = json.sorted || true
      this.bst = json.bst || ''
      this.ps = json.ps || 25
      this.pg = json.pg || 1
    }
    else {
      this.Section = 'site'
      this.site = 'Dayton'
      this.sorted = true
      this.bst = ''
      this.ps = 25
      this.pg = 1
    }
  }
}

export default SearchQuery
