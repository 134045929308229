/** store/index.js **/
import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';

// import the super store that hydrates from localstorage
import Superstore from '@/js/store/plugins/superstore'

const expireTime = 60 * 60 * 1000; // 1 hour
// superstore
const superstore = new Superstore('ds_ucommerce_vuex')

// import the auto exporter
import modules from '@/js/store/modules';

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    modules: superstore.load(modules), // all your modules automatically imported  and hydrated by superstore:)
    //CALL GLOBAL RESET FROM ANY VUE COMPONENT like so
    // this.$store.dispatch('reset');
    actions: {
        reset({ commit }) {
            // resets state of all the modules
            Object.keys(modules).forEach(moduleName => {
                commit(`${moduleName}/RESET`);
            })
        }
    },
    strict: debug,
    //plugins: debug ? [createLogger(), superstore.save] : [superstore.save],
    plugins: [superstore.save]
});
