// search results typesd
export const SEARCH_RESULT_LIST = 'SEARCH_RESULT_LIST'
export const INIT_SEARCH_RESULT_LIST = 'INIT_SEARCH_RESULT_LIST'
export const REQUEST_SEARCH_RESULT_LIST = 'REQUEST_SEARCH_RESULT_LIST'
export const SEARCH_RESULT_LIST_FAILURE = 'SEARCH_RESULT_LIST_FAILURE'
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY'
export const ACTIVE_SEARCH_QUERY = 'ACTIVE_SEARCH_QUERY'
export const SEARCH_RESULT_TOTAL_COUNT = 'SEARCH_RESULT_TOTAL_COUNT'
export const SEARCH_RESULT_TOTAL_PAGES = 'SEARCH_RESULT_TOTAL_PAGES'
export const SEARCH_RESULT_CURRENT_PAGE = 'SEARCH_RESULT_CURRENT_PAGE'
export const SEARCH_RESULT_CURRENT_START_PAGE = 'SEARCH_RESULT_CURRENT_START_PAGE'
export const SEARCH_RESULT_CURRENT_END_PAGE = 'SEARCH_RESULT_CURRENT_END_PAGE'
export const SET_ACTIVE_SEARCH_FILTERS = 'SET_ACTIVE_SEARCH_FILTERS'
export const ACTIVE_SEARCH_FILTERS = 'ACTIVE_SEARCH_FILTERS'
export const SET_SEARCH_RESULT = 'SET_SEARCH_RESULT'
export const SEARCH_RESULT_UPDATE = 'SEARCH_RESULT_UPDATE'
export const GET_SEARCH_PRICES = 'GET_SEARCH_PRICES'

// customer picker types
export const CUSTOMER_LIST = 'CUSTOMER_LIST'
export const INIT_CUSTOMER_LIST = 'INIT_CUSTOMER_LIST'
export const REQUEST_CUSTOMER_LIST = 'REQUEST_CUSTOMER_LIST'
export const CUSTOMER_LIST_FAILURE = 'GET_CUSTOMER_LIST_FAILURE'
export const SET_ACTIVE_CUSTOMER = 'SET_ACTIVE_CUSTOMER'
export const SET_ACTIVE_CUSTOMER_NAME = 'SET_ACTIVE_CUSTOMER_NAME'
export const CUSTOMER_DISTRIBUTOR = 'CUSTOMER_DISTRIBUTOR'
export const ACTIVE_CUSTOMER = 'ACTIVE_CUSTOMER'
export const ACTIVE_CUSTOMER_NAME = 'ACTIVE_CUSTOMER_NAME'
export const ACTIVE_CUSTOMER_FAILURE = 'SET_ACTIVE_CUSTOMER_FAILURE'
export const IS_INTERNAL_EMPLOYEE = 'IS_INTERNAL_EMPLOYEE'
export const USER_INFO = 'USER_INFO'
export const SET_USER_INFO = 'SET_USER_INFO'

// shipto picker types
export const SHIPTO_LIST = 'SHIPTO_LIST'
export const INIT_SHIPTO_LIST = 'INIT_SHIPTO_LIST'
export const REQUEST_SHIPTO_LIST = 'REQUEST_SHIPTO_LIST'
export const SHIPTO_LIST_FAILURE = 'GET_SHIPTO_LIST_FAILURE'
export const SHIPTO_DISTRIBUTOR = 'SHIPTO_DISTRIBUTOR'
export const SET_ACTIVE_SHIPTO = 'SET_ACTIVE_SHIPTO'
export const ACTIVE_SHIPTO = 'ACTIVE_SHIPTO'
export const ACTIVE_SHIPTO_FAILURE = 'SET_ACTIVE_SHIPTO_FAILURE'

// shipping picker types
export const SHIPPING_LIST = 'SHIPPING_LIST'
export const SHIPPING_CHECKOUT_MODEL = 'SHIPPING_CHECKOUT_MODEL'
export const INIT_SHIPPING_LIST = 'INIT_SHIPPING_LIST'
export const REQUEST_SHIPPING_LIST = 'REQUEST_SHIPPING_LIST'
export const SHIPPING_LIST_FAILURE = 'GET_SHIPPING_LIST_FAILURE'
export const SET_ACTIVE_SHIPPING = 'SET_ACTIVE_SHIPPING'
export const ACTIVE_SHIPPING = 'ACTIVE_SHIPPING'
export const SACTIVE_SHIPPING_FAILURE = 'SET_ACTIVE_SHIPPING_FAILURE'

// Freight Items types
export const SET_SHIPPING_INFO = 'SET_SHIPPING_INFO'
export const SHIPPING_INFO = 'SHIPPING_INFO'
export const FREIGHT_TERMS_OPTIONS = { PrepayAndBill: 'PB', Collect: 'COL', ThirdParty: '3PB' }
export const VEHICLE_TYPE_OPTIONS = { Flatbed: 'FB', Van: 'VAN' }
export const SHIPPING_VIA_OPTIONS = { Pickup: 'P/U', BestWay: 'B/W', UPS: 'GND', FreeUPS: 'FGND' }

// Router types
export const SET_PURL = 'SET_PURL'
export const ACTIVE_PURL = 'ACTIVE_PURL'
