class ShipTo {
    constructor (shipTo) {
      if(shipTo) {
          this.ShipToId = shipTo.ShipToId
          this.CompanyName = shipTo.CompanyName
          this.AddressLine1 = shipTo.AddressLine1
          this.AddressLine2 = shipTo.AddressLine2
          this.AddressLine3 = shipTo.AddressLine3
          this.City = shipTo.City
          this.County = shipTo.County
          this.State = shipTo.State
          this.PostalCode = shipTo.PostalCode
          this.Country = shipTo.Country
          this.Contact = shipTo.Contact
          this.PhoneNumber = shipTo.PhoneNumber
          this.WarehouseId = shipTo.WarehouseId
          this.WarehouseName = shipTo.WarehouseName  
      }
      
    }
  
    fromJson (json) {
        this.ShipToId = json.ShipToId;
        this.CompanyName = json.CompanyName || "";
        this.AddressLine1 = json.AddressLine1 || "";
        this.AddressLine2 = json.AddressLine2 || "";
        this.AddressLine3 = json.AddressLine3 || "";
        this.City = json.City || "";
        this.County = json.County || "";
        this.State = json.State || "";
        this.PostalCode = json.PostalCode || "";
        this.Country = json.Country || "";
        this.Contact = json.Contact || "";
        this.PhoneNumber = json.PhoneNumber || "";
        this.WarehouseId = json.WarehouseId || "";
        this.WarehouseName = json.WarehouseName || "";
    }
  
  }
  
  export default ShipTo
