const storage = window.localStorage

function load(key) {
  const item = storage.getItem(key)

  if (item !== null) {
    try {
      const data = JSON.parse(item)

      if (data.expire === null)
        return data.state

      if (data.expire >= new Date().getTime())
        return data.state

      storage.removeItem(key)
    }
    catch (err) {
      return null
    }
  }

  return null
}

/**
 * Vuex plugin to hydrate, sync and clear state to/from local storage
 *
 * @param   {string}  key   An optional local storage item name
 * @param   {number}  expire   An optional local storage expiry amount from current time that a mutation occurs
 */
function Superstore(key = 'vuex', expire = null) {
  Object.assign(this, {

    /**
     * Load data from local storage and hydrate module states
     *
     * @param   {Object}  modules   The hash of module definitions
     * @returns {Object}            The updated modules
     */
    load(modules) {
      const states = load(key)
      if (states) {
        Object
          .keys(modules)
          .forEach((name) => {
            const module = modules[name]
            const state = states[name]
            if (module.state && state) {
              if (module.hydrate instanceof Function)
                module.hydrate(state)

              Object.assign(module.state, state)
            }
          })
      }

      return modules
    },

    /**
     * Vuex mutation handler; assign to Store plugins array
     *
     * @param   {Object}  store     The store to save to local storage
     */
    save(store) {
      store.subscribe((mutation, state) => {
        const stringifyValue = JSON.stringify({
          state,
          expire: expire !== null ? new Date().getTime() + expire : null,
        })

        storage.setItem(key, stringifyValue)
      })
    },

    /**
     * Clear stored data
     */
    clear() {
      storage.removeItem(key)
    },
  })
}

export default Superstore
