class Customer {
  constructor(customer) {
    if (customer) {
      this.ErpId = customer.ErpId
      this.Name = customer.Name
    }
  }

  fromJson(json) {
    this.ErpId = json.ErpId
    this.Name = json.Name || ''
  }
}

export default Customer
