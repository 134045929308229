class ShippingMethod {
    constructor (shippingMethod) {
      if(shippingMethod) {
        this.ShippingMethodId = shippingMethod.ShippingMethodId;
        this.Name = shippingMethod.Name;      
      }
      
    }
  
    fromJson (json) {
      this.ShippingMethodId = json.ShippingMethodId ;
      this.Name = json.Name  || "";
    }
  
  }
  
  export default ShippingMethod